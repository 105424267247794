import React from 'react';

const Icon1 = () => (
  <svg width="72px" height="71px" viewBox="0 0 72 71" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Group 4</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"></feColorMatrix>
      </filter>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Help-center-2" transform="translate(-304.000000, -1982.000000)">
        <g id="Group-4" transform="translate(304.000000, 1982.000000)">
          <rect id="Rectangle-Copy-9" fill="#6161FF" x="0" y="0" width="72" height="71" rx="4"></rect>
          <g filter="url(#filter-1)" id="headset">
            <g transform="translate(19.000000, 19.000000)">
              <path d="M14.875,22.3125 L14.875,12.7739062 C14.875,11.6005078 13.9233984,10.6489062 12.75,10.6489062 L9.5625,10.6489062 C7.21503906,10.6489062 5.3125,12.5514453 5.3125,14.8989062 L5.3125,20.1875 C5.3125,22.5349609 7.21503906,24.4375 9.5625,24.4375 L12.75,24.4375 C13.9233984,24.4375 14.875,23.4858984 14.875,22.3125 Z M11.6875,21.25 L9.5625,21.25 C8.97679687,21.25 8.5,20.7732031 8.5,20.1875 L8.5,14.8989062 C8.5,14.3132031 8.97679687,13.8364062 9.5625,13.8364062 L11.6875,13.8364062 L11.6875,21.25 Z M17,0 C7.51585938,0 0.304140625,7.89105469 0,17 L0,18.0625 C0,18.6495312 0.47546875,19.125 1.0625,19.125 L2.125,19.125 C2.71203125,19.125 3.1875,18.6495312 3.1875,18.0625 L3.1875,17 C3.1875,9.38386719 9.38386719,3.1875 17,3.1875 C24.6161328,3.1875 30.8125,9.38386719 30.8125,17 L30.8045313,17 C30.8098437,17.1613672 30.8125,28.0048438 30.8125,28.0048438 C30.8125,29.5554297 29.5554297,30.8125 28.0048438,30.8125 L21.25,30.8125 C21.25,29.0520703 19.8229297,27.625 18.0625,27.625 L15.9375,27.625 C14.1770703,27.625 12.75,29.0520703 12.75,30.8125 C12.75,32.5729297 14.1770703,34 15.9375,34 L28.0048438,34 C31.3158594,34 34,31.3158594 34,28.0048438 L34,17 C33.6958594,7.89105469 26.4841406,0 17,0 Z M24.4375,24.4375 C26.7849609,24.4375 28.6875,22.5349609 28.6875,20.1875 L28.6875,14.8989062 C28.6875,12.5514453 26.7849609,10.6489062 24.4375,10.6489062 L21.25,10.6489062 C20.0766016,10.6489062 19.125,11.6005078 19.125,12.7739062 L19.125,22.3125 C19.125,23.4858984 20.0766016,24.4375 21.25,24.4375 L24.4375,24.4375 Z M22.3125,13.8364062 L24.4375,13.8364062 C25.0232031,13.8364062 25.5,14.3132031 25.5,14.8989062 L25.5,20.1875 C25.5,20.7732031 25.0232031,21.25 24.4375,21.25 L22.3125,21.25 L22.3125,13.8364062 Z" fill="#36383B" fillRule="nonzero"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon1;
