import React from 'react';

const Icon2 = () => (
    <svg width="72px" height="71px" viewBox="0 0 72 71" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Group 6</title>
        <defs>
            <filter id="filter-1">
                <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"></feColorMatrix>
            </filter>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Help-center-2" transform="translate(-558.000000, -1982.000000)">
                <g id="Group-6" transform="translate(558.000000, 1982.000000)">
                    <rect id="Rectangle-Copy-6" fill="#49C592" x="0" y="0" width="72" height="71" rx="4"></rect>
                    <g filter="url(#filter-1)" id="Group-5-Copy-2">
                        <g transform="translate(27.000000, 19.000000)">
                            <path d="M9.99989924,34 C10.5498316,34 10.9997985,33.521862 10.9997985,32.9375 L10.9997985,29.75 L11.1560327,29.75 C14.2307351,29.75 17.0679126,27.6382942 17.8053871,24.4640884 C18.6740129,20.7320442 16.5430021,17.0797005 13.2433468,16.0769272 L5.97528039,13.8722916 C4.9878921,13.5734634 4.18798491,12.7300911 4.03175066,11.6476822 C3.78800081,9.9609375 5.01913895,8.5 6.56274561,8.5 L11.1747686,8.5 C12.1684429,8.5 13.1433203,8.83203125 13.9495134,9.43630219 C14.3494365,9.73513031 14.886919,9.68869781 15.2306343,9.32346344 L16.6617035,7.80273438 C17.0867095,7.35118484 17.0429517,6.59412766 16.567963,6.20236969 C15.0244173,4.93397141 13.1496063,4.25 11.1747686,4.25 L10.9997985,4.25 L10.9997985,1.0625 C10.9997985,0.47813797 10.5498316,0 9.99989924,0 L8.00010076,0 C7.45016839,0 7.00020152,0.47813797 7.00020152,1.0625 L7.00020152,4.25 L6.84396727,4.25 C3.7692649,4.25 0.932087427,6.36170578 0.194612915,9.53591156 C-0.674012931,13.2679558 1.45699791,16.9202995 4.75665319,17.9230728 L12.0247196,20.1277084 C13.0121079,20.4265366 13.8120151,21.2699089 13.9682493,22.3523178 C14.2119992,24.0390625 12.9808611,25.5 11.4372544,25.5 L6.82523136,25.5 C5.83155708,25.5 4.85667973,25.1679688 4.05048656,24.5636978 C3.65056348,24.2648697 3.11308102,24.3113022 2.76936566,24.6765366 L1.33829649,26.1972656 C0.913290493,26.6488152 0.957048291,27.4058723 1.43203705,27.7976303 C2.96935772,29.0660286 4.84416879,29.75 6.82523136,29.75 L7.00020152,29.75 L7.00020152,32.9375 C7.00020152,33.521862 7.45016839,34 8.00010076,34 L9.99989924,34 Z" id="" fill="#36383B" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Icon2;
