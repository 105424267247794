import React from 'react';

const Icon3 = () => (
<svg width="72px" height="71px" viewBox="0 0 72 71" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Group 7</title>
    <defs>
        <filter id="filter-1">
            <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"></feColorMatrix>
        </filter>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Help-center-2" transform="translate(-813.000000, -1982.000000)">
            <g id="Group-7" transform="translate(813.000000, 1982.000000)">
                <rect id="Rectangle-Copy-7" fill="#FFC054" x="0" y="0" width="72" height="71" rx="4"></rect>
                <g filter="url(#filter-1)" id="window-close-regular">
                    <g transform="translate(36.000000, 35.500000) scale(-1, 1) translate(-36.000000, -35.500000) translate(17.000000, 18.000000)">
                        <path d="M34.4375,0.5 L3.5625,0.5 C1.59570312,0.5 0,2.13169643 0,4.14285714 L0,30.8571429 C0,32.8683036 1.59570312,34.5 3.5625,34.5 L34.4375,34.5 C36.4042969,34.5 38,32.8683036 38,30.8571429 L38,4.14285714 C38,2.13169643 36.4042969,0.5 34.4375,0.5 Z M34.4375,30.4017857 C34.4375,30.6522321 34.2371094,30.8571429 33.9921875,30.8571429 L4.0078125,30.8571429 C3.76289063,30.8571429 3.5625,30.6522321 3.5625,30.4017857 L3.5625,4.59821429 C3.5625,4.34776786 3.76289063,4.14285714 4.0078125,4.14285714 L33.9921875,4.14285714 C34.2371094,4.14285714 34.4375,4.34776786 34.4375,4.59821429 L34.4375,30.4017857 Z M26.4589844,12.8401786 L21.9019531,17.5 L26.4589844,22.1598214 C26.8003906,22.5089286 26.8003906,23.078125 26.4589844,23.4348214 L24.8039062,25.1272321 C24.4625,25.4763393 23.9058594,25.4763393 23.5570313,25.1272321 L19,20.4674107 L14.4429688,25.1272321 C14.1015625,25.4763393 13.5449219,25.4763393 13.1960937,25.1272321 L11.5410156,23.4348214 C11.1996094,23.0857143 11.1996094,22.5165179 11.5410156,22.1598214 L16.0980469,17.5 L11.5410156,12.8401786 C11.1996094,12.4910714 11.1996094,11.921875 11.5410156,11.5651786 L13.1960937,9.87276786 C13.5375,9.52366071 14.0941406,9.52366071 14.4429688,9.87276786 L19,14.5325893 L23.5570313,9.87276786 C23.8984375,9.52366071 24.4550781,9.52366071 24.8039062,9.87276786 L26.4589844,11.5651786 C26.8078125,11.9142857 26.8078125,12.4834821 26.4589844,12.8401786 Z" id="Shape" fill="#000000" fill-rule="nonzero"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default Icon3;
